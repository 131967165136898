import {AllJobOfferListI, SalaryInPeriod} from './job-offers.model';

import {ContractTypeDto, CurrencyDto, RolePositionDto} from '../directories';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';

export class SendJobOfferDirectories {
  constructor(
    public readonly offers: AllJobOfferListI,
    public readonly positions: RolePositionDto[],
    public readonly contractType: ContractTypeDto[],
    public readonly currency: CurrencyDto[],
    public readonly periods: SalaryInPeriod[],
  ) { }
}

export interface SendJobOfferForm {
  jobOfferId: number;
  private: boolean;
  position: number;
  contractType: number[];
  negotiable: boolean;
  offeredSalary: number;
  offeredSalaryMax: number;
  offeredSalaryCurrency: number;
  salaryPeriod: number;
  note: string;
  name?: string;
}

export class SendJobOfferPayload {

  readonly userId: number;
  readonly jobOfferId: number;
  readonly private: ConditionalStatus;
  readonly name: string | null = null;
  readonly position: number;
  readonly contractType: number[];
  readonly negotiable: ConditionalStatus;
  readonly offeredSalary: number | null;
  readonly offeredSalaryMax: number | null;
  readonly offeredSalaryCurrency: number | null;
  readonly salaryPeriod: number | null;
  readonly note: string;
  constructor(
    form: SendJobOfferForm,
    userId: number,
  ) {
    this.userId = userId;
    this.jobOfferId = form.jobOfferId;
    this.private = booleanToConditionalStatus(form.private);
    this.name = this.private && form.name ? form.name : null;
    this.position = form.position;
    this.contractType = form.contractType;
    this.negotiable = form.negotiable ? ConditionalStatus.NO : ConditionalStatus.YES;
    this.offeredSalary = form.offeredSalary;
    this.offeredSalaryMax = form.offeredSalaryMax;
    this.offeredSalaryCurrency = form.offeredSalaryCurrency;
    this.salaryPeriod = form.salaryPeriod;
    this.note = form.note;
  }

}
