import {Params} from '@angular/router';

import {AvailabilityFormI} from '@controls/availability-form';
import {ExpectedSalaryFormI} from '@controls/expected-salary-form';
import {LanguageLevelFormI} from '@controls/language-level-form';
import {PhoneNumber} from '@controls/phone-number';
import {CheckboxGroupedValue, CheckboxGroupItem} from '@controls/seazone-checkbox-group-deprecated';
import {RangeDto} from '@controls/seazone-range-slider';
import {parseParamsToArray} from '@helpers/parse-params-to-array';

import {CompanyReadyStatus, CompanyType} from '../auth';
import {BoatPayload, BoatShortViewDto} from '../boat';
import {ContractPeriod,CrewPositionDto, CrewSearchStatus, CrewSearchStatusItem, ExpectedSalaryDto} from '../crew';
import {CoverLetterDto, CrewCvContactsDto} from '../crew-cv';
import {CoupleDto, CrewSearchContractItemDto, CrewSearchCvDocumentsDto, CrewSearchSortType} from '../crew-search';
import {
  BoatAreaDto,
  BoatTypeDto,
  BoatTypeSectionDto,
  ContractTypeDto,
  CountryDto,
  CurrencyDto,
  CvDocumentDefaultDto,
  CvVisaGroupDto,
  LanguageDto,
  LanguageLevelDto,
  PositionsGroupDto,
  RolePositionDto,
  RolePositionTypeDto,
} from '../directories';
import {booleanToConditionalStatus, ConditionalItem, ConditionalStatus, LabeledItem} from '../general';
import {ContractInvitationDto, CrewJobOfferCountType, SearchBoatTypes} from '../job-search';
import {LocationDto} from '../location';
import {PaginationDto} from '../response';
import {MatchingResultType, SearchChipsItemDto, SearchMatchingDto} from '../search';

export type JobOfferFormMode = 'add' | 'edit';

export type JobOffersReadyType = 'matches' | 'applied' | 'sent';

export enum CrewOfferReadyMode {
  Applied = 1,
  Received
}

export enum PublishStatus {
  UnPublished,
  Published
}

export enum HiredStatus {
  NotHired,
  Hired
}

export enum UpdateCrewStatus {
  NotUpdate,
  Update
}

export enum UnpublishReason {
  ClosedByMyCompany = 1,
  ClosedByOtherCompany = 2,
  Cancelled = 3
}

export enum TattooStatus {
  Tattoo = 1,
  NoTattoo
}

export enum SmokingStatus {
  Smoking = 1,
  NoSmoking
}

export enum FlexibleDate {
  Austere,
  Flexible
}

export enum JobOfferStatusType {
  STATUS_NEW = 1,
  STATUS_PENDING = 2,
  STATUS_IN_PROCESS = 3,
  STATUS_ACCEPTED = 4,
  STATUS_DECLINE_BY_BOAT = 5,
  STATUS_DECLINE = 6,
  STATUS_DECLINE_BY_CREW = 7,
  STATUS_HIRED = 8,
  STATUS_CLOSED = 9,
  STATUS_DECLINE_BY_ME = 10,
  STATUS_DECLINE_BY_YACHT = 11,
  STATUS_CONTACTS_SHARED = 12,
  STATUS_HIRED_CLOSED = 13
}

export enum JobOfferInvitationStatusType {
  INV_STATUS_SENT_OFFER = 1,
  INV_STATUS_OPENED_CONTACTS = 2,
  INV_STATUS_SENT_ADD_AS_CREW = 3,
  INV_STATUS_ACCEPTED = 4,
  INV_STATUS_REJECTED = 5,
  INV_STATUS_DECLINE = 6,
  INV_STATUS_VIEWED = 8,
  INV_STATUS_APPLIED = 9,
  INV_STATUS_HIRED = 10,
  INV_STATUS_CLOSED = 11,
  INV_STATUS_VIEWED_CONTACTS = 13,
}

export enum PeriodForSalary {
  DAILY,
  MONTHLY,
}

export enum JobOfferInvitationType {
  BOAT = 1,
  CREW
}

export class JobOfferTitleItem {
  constructor(
    public readonly id: JobOfferStatusType | null,
    public readonly title: string,
  ) {
  }
}

export class JobOfferBadgeItem extends JobOfferTitleItem {

  constructor(
    id: JobOfferStatusType | null,
    title: string,
    public readonly style: string | null = null,
  ) {
    super(id, title);
  }
}

export class CrewJobOfferFilterNavItem extends JobOfferTitleItem {
  constructor(
    id: JobOfferStatusType | null,
    title: string,
    public readonly type: CrewJobOfferCountType,
    public readonly count: number | null = null,
    public readonly actionsCount: number | null = null,
  ) {
    super(id, title);
  }
}

// interfaces

export interface JobOffersListPaginationI {
  list: JobOffersListItemI[];
  pagination: PaginationDto;
}

export interface JobOfferPlaceInputI {
  address: string;
  lat: number;
  lng: number;
  type: string;

}

export interface PositionUser {
  icon: string;
  name: string;
}

export interface UnpublishOfferParamsI {
  updateCrew: UpdateCrewStatus;
  reason: UnpublishReason;
}

export interface JobOffersListItemI {
  id: number;
  name: string;
  published: PublishStatus;
  hired: HiredStatus;
  jobOfferKindContract: EntryDto[];
  estimatedStartDate: string;
  endDate: string;
  offeredSalary: number;
  offeredSalaryMax: number;
  negotiable: boolean;
  offeredSalaryCurrency: string;
  salaryPeriod: string;
  dateCreate: string;
  dateUpdate: string;
  lastLogin: string;
  position: PositionUser;
  createdByCrewAgency: number;
  view: JobOfferItemViewI;
  form: JobOffersFormI;
  covidVaccination: boolean;
  couple: number | null;
  boat?: BoatShortViewDto;
  reasonOfUnpublishing?: UnpublishOfferReasonI;
  additional?: JobOffersListItemAdditionalI;
}

export interface JobOffersListItemAdditionalI {
  applied: string;
  appliedActions: string;
  sent: string;
  sentActions: string;
}

export interface JobOffersNewActionsI {
  published: number;
  unpublished: number;
}

export interface JobOffersFormI {
  id: number;
  boatId: number;
  joiningPlace: JobOfferPlace | null;
  name: string;
  jobOfferKindContract: EntryDto[];
  jobOfferSkill: EntryDto[] | null;
  jobOfferCruisingArea: EntryDto[] | null;
  languageOneValue: number | null;
  languageOneLevel: number | null;
  languageTwoValue: number | null;
  languageTwoLevel: number | null;
  languageThreeValue: number | null;
  languageThreeLevel: number | null;
  jobOfferDocument: EntryDto[];
  published: PublishStatus;
  positionGroupId: number;
  positionId: number;
  offeredSalary: number;
  offeredSalaryMax: number;
  negotiable: ConditionalStatus;
  offeredSalaryCurrencyId: number;
  salaryPeriod: number;
  estimatedStartDate: string;
  flexible: FlexibleDate;
  endDate: string | null;
  experienceFrom: number;
  experienceTo: number;
  description: string | null;
  tattooStatusId: TattooStatus;
  smokingStatusId: SmokingStatus;
  createdByCrewAgency: number | null;
  useCurrentBoatPosition: boolean;
  covidVaccination: boolean;
  questions: JobOfferQuestionDto[] | null;
  coupleConnect: number | null;
  boatType: EntryDto[] | null;
}

export interface JobOfferItemViewI {
  id: number;
  boatId: number;
  joiningPlace: JobOfferPlace | null;
  name: string;
  jobOfferKindContract: EntryDto[];
  jobOfferSkill: EntryDto[];
  jobOfferLanguage: LanguageEntryDto[];
  jobOfferDocument: EntryDto[];
  published: PublishStatus;
  position: string;
  offeredSalary: number;
  offeredSalaryMax: number;
  negotiable: boolean;
  offeredSalaryCurrency: string;
  salaryPeriod: string;
  estimatedStartDate: string | null;
  flexible: FlexibleDate;
  endDate: string | null;
  experienceFrom: number;
  experienceTo: number;
  description: string | null;
  tattooStatusId: string;
  smokingStatusId: string;
  createdByCrewAgency: CreatedByCrewAgencyI | number | null;
  reasonOfUnpublishing: UnpublishOfferReasonI | null;
  jobOfferCruisingArea: EntryDto[];
  covidVaccination: boolean;
  questions: JobOfferQuestionDto[];
  couple: JobOfferCoupleDto | null;
  boatType: EntryDto[];
}

export interface JobOffersPayloadFormI {
  id?: number | null;
  name: string;
  published: PublishStatus;
  positionGroupId: number;
  positionId: number;
  jobOfferKindContract: number[];
  offeredSalary: number;
  offeredSalaryCurrencyId: number;
  salaryPeriod: number;
  offeredSalaryMax: number;
  negotiable: boolean;
  estimatedStartDate: string;
  flexible: boolean | null;
  endDate: string | null;
  // experienceFrom: number | null;
  // experienceTo: number | null;
  experience: RangeDto | null;
  greeny: boolean | null;
  joiningPlace: JobOfferPlaceInputI;
  jobOfferCruisingArea: number[];
  description: string | null;
  jobOfferSkill: number[] | null;
  languages: LanguageLevelFormI;
  jobOfferDocument: number[];
  tattooStatusId: SmokingStatus;
  smokingStatusId: SmokingStatus;
  createdByCrewAgency: number;
  reasonOfUnpublishing: string | null;
  useCurrentBoatPosition: boolean | null;
  covidVaccination: boolean;
  question: string[];
  coupleConnect: number | null;
  coupleRemove: number | null;
  boatType: number[] | null;
}

export interface JobOfferFormModeData {
  mode: JobOfferFormMode;
  item: JobOfferInputFormDto;
  changeHiredParams: ChangeHiredStatusParams;
}

export interface WatchContactsI {
  contacts: CrewCvContactsDto;
  jobOfferInvitationId: number;
  status: JobOfferStatusType;
}

export interface UnpublishReasonI {
  description: string;
  id: UnpublishReason;
  sort: number;
  status: number;
}

// classes

export class SalaryInPeriod {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}

export class JobOffersParams {
  constructor(
    public readonly page: number,
    public readonly search: string,
    public readonly boatId: number | null,
    public readonly published: number,
    public readonly limit: number,
  ) {
  }
}

export class JobOfferNavItem {
  readonly param: JobOfferStatusType | null;
  readonly title: string;
  readonly type: JobOfferCountType;
  readonly count: number | null;
  readonly actionsCount: number | null;

  constructor(
    id: JobOfferStatusType | null,
    title: string,
    type: JobOfferCountType,
    count = null,
    actionsCount = null,
  ) {
    this.param = id;
    this.title = title;
    this.type = type;
    this.count = count;
    this.actionsCount = actionsCount;
  }
}

export class JobOffersListParams {
  constructor(
    public readonly page: number | null,
    public readonly filter: JobOfferStatusType | null,
    public readonly limit: number | null,
    public readonly sort: CrewSearchSortType | null,
  ) {
  }
}

export class JobOffersListPayload extends JobOffersListParams {
  readonly boatId: number;
  readonly jobOfferId: number;
  readonly positionGroup: number[] | null;
  readonly position: number[] | null;
  readonly lookingJobStatus: number | null;
  readonly availabilityStatus: number[] | null;
  readonly availabilityDate: string | null;
  readonly expectedSalaryCurrency: number | null;
  readonly expectedSalaryMin: number | null;
  readonly expectedSalaryMax: number | null;
  readonly professionalSkills: number[] | null;
  readonly languageOneValue: number | null;
  readonly languageOneLevel: number | null;
  readonly languageTwoValue: number | null;
  readonly languageTwoLevel: number | null;
  readonly languageThreeValue: number | null;
  readonly languageThreeLevel: number | null;
  readonly yachtExperienceMin: number | null;
  readonly yachtExperienceMax: number | null;
  readonly greeny: ConditionalStatus | null;
  readonly notSmocking: ConditionalStatus | null;
  readonly notVisibleTattoos: ConditionalStatus | null;
  readonly covidVaccination: ConditionalStatus | null = null;
  readonly passportCountry: number | null = null;
  readonly visa: number[] | null = null;
  readonly couple: ConditionalStatus | null = null;
  readonly sort: CrewSearchSortType | null;
  readonly page: number | null;
  readonly limit: number | null;
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly distance: number | null = null;
  readonly regions: number[] | null = null;
  readonly search: string | null = null;
  readonly boatType: number[] | null = null;

  constructor(
    boatId: number,
    jobOfferId: number,
    page: number | null,
    filter: JobOfferStatusType | null,
    limit: number | null,
    sort: CrewSearchSortType | null,
    params: Params,
    maxDistance: number,
  ) {
    super(page, filter, limit, sort);
    const {
      positionGroup,
      position,
      availabilityStatus,
      professionalSkills,
      myLocationLat,
      myLocationLng,
      distance,
      regions,
      boatType,
    } = params;
    this.boatId = boatId;
    this.jobOfferId = jobOfferId;
    this.positionGroup = parseParamsToArray(positionGroup, true);
    this.position = parseParamsToArray(position, true);
    this.lookingJobStatus = params.lookingJobStatus;
    this.availabilityStatus = parseParamsToArray(availabilityStatus, true);
    this.availabilityDate = params.availabilityDate;
    this.expectedSalaryCurrency = params.expectedSalaryCurrency;
    this.expectedSalaryMin = params.expectedSalaryMin;
    this.expectedSalaryMax = params.expectedSalaryMax;
    this.professionalSkills = parseParamsToArray(professionalSkills, true);
    this.languageOneValue = params.languageOneValue;
    this.languageOneLevel = params.languageOneLevel;
    this.languageTwoValue = params.languageTwoValue;
    this.languageTwoLevel = params.languageTwoLevel;
    this.languageThreeValue = params.languageThreeValue;
    this.languageThreeLevel = params.languageThreeLevel;
    this.yachtExperienceMin = params.yachtExperienceMin;
    this.yachtExperienceMax = params.yachtExperienceMax;
    this.notSmocking = params.notSmocking;
    this.notVisibleTattoos = params.notVisibleTattoos;
    this.greeny = params.greeny;
    this.covidVaccination = params.covidVaccination;
    this.passportCountry = params.passportCountry;
    this.visa = parseParamsToArray(params.visa, true);
    this.couple = params.couple;
    this.sort = params.sort;
    this.page = params.page;
    this.limit = params.limit;
    if (myLocationLat && myLocationLng) {
      this.myLocationLat = params.myLocationLat;
      this.myLocationLng = params.myLocationLng;
      this.distance = distance || maxDistance;
    }
    this.regions = parseParamsToArray(regions, true);
    this.search = params.search;
    this.boatType = parseParamsToArray(boatType, true);
  }
}

export class JobOfferPlace {
  readonly name: string;
  readonly lat: number;
  readonly lng: number;
  readonly type?: string;

  constructor(
    place: JobOfferPlaceInputI,
  ) {
    this.name = place.address;
    this.lat = place.lat;
    this.lng = place.lng;
    this.type = place.type;
  }
}

export class EntryDto {
  constructor(
    public id: number,
    public name: string,
  ) {
  }
}

export class LanguageEntryDto extends EntryDto {
  constructor(
    id: number,
    name: string,
    public readonly level: EntryDto,
  ) {
    super(id, name);
  }
}

export class ChangeHiredStatusParams {
  constructor(
    public hired: HiredStatus,
    public publishStatus: PublishStatus,
  ) {
  }
}

export class ChangedStatusParams {
  constructor(
    public id: number,
    public published: PublishStatus,
    public hired: HiredStatus,
    public createdByCrewAgency: number | null,
    public updateCrew: UpdateCrewStatus | null = null,
    public reason: number | null = null,
  ) {
  }
}

export class ChangedStatusItem {
  constructor(
    public published: PublishStatus,
    public item: JobOffersListItemDto,
  ) {
  }
}

export class ChangeStatusPayload {
  id: number;
  published: PublishStatus;
  updateCrew: UpdateCrewStatus | null;
  reason: number | null;

  constructor(
    params: ChangedStatusParams,
  ) {
    this.id = params.id;
    this.published = params.published;
    this.updateCrew = params.updateCrew;
    this.reason = params.reason;
  }
}

export class JobOffersDirectories {
  readonly positions: RolePositionDto[] = this.rolePositionType.reduce((acc, value) => [...acc, ...value.positions], []);

  constructor(
    public readonly conditionalStatuses: ConditionalItem[] = [],
    public readonly rolePositionType: RolePositionTypeDto[],
    public readonly contractType: ContractTypeDto[],
    public readonly currency: CurrencyDto[],
    public readonly periods: ContractPeriod[],
    public readonly language: LanguageDto[],
    public readonly documents: CvDocumentDefaultDto[],
    public readonly boatArea: BoatAreaDto[],
    public readonly positionsGroup: PositionsGroupDto[],
    public readonly boatLocation: LocationDto | null,
    public readonly offers: ShortJobOfferItemI[] | null,
    public readonly boatTypes: BoatTypeDto[],
    public readonly chipsExperiences: SearchChipsItemDto[],
    public readonly chipsGreeny: SearchChipsItemDto,
  ) {
  }
}

export class JobOffersListPagination {
  readonly list: JobOffersListItemDto[];
  readonly pagination: PaginationDto;

  constructor(data: JobOffersListPaginationI) {
    this.list = data.list && data.list.length ? data.list.map(el => new JobOffersListItemDto(el)) : [];
    this.pagination = data.pagination;
  }
}

export class JobOffersListItemDto {
  readonly id: number;
  readonly name: string;
  readonly published: PublishStatus;
  readonly hired: HiredStatus;
  readonly jobOfferKindContract: EntryDto[];
  readonly estimatedStartDate: string;
  readonly endDate: string;
  readonly offeredSalary: number;
  readonly offeredSalaryMax: number;
  readonly negotiable: boolean;
  readonly offeredSalaryCurrency: string;
  readonly salaryPeriod: string;
  readonly dateCreate: string;
  readonly dateUpdate: string;
  readonly lastLogin: string;
  readonly position: PositionUser;
  readonly additional: JobOfferItemAdditionalDto | null;
  readonly createdByCrewAgency: number;
  readonly view: JobOfferItemViewI;
  readonly form: JobOfferInputFormDto;
  readonly boat?: BoatShortViewDto;
  readonly covidVaccination: boolean;
  readonly couple: number | null;
  readonly reasonOfUnpublishing?: UnpublishOfferReasonI;

  constructor(data: JobOffersListItemI) {
    this.id = data.id;
    this.name = data.name;
    this.published = data.published;
    this.hired = data.hired;
    this.jobOfferKindContract = data.jobOfferKindContract;
    this.endDate = data.endDate;
    this.estimatedStartDate = data.estimatedStartDate;
    this.offeredSalary = data.offeredSalary;
    this.offeredSalaryMax = data.offeredSalaryMax;
    this.negotiable = data.negotiable;
    this.offeredSalaryCurrency = data.offeredSalaryCurrency;
    this.salaryPeriod = data.salaryPeriod;
    this.dateUpdate = data.dateUpdate;
    this.lastLogin = data.lastLogin;
    this.dateCreate = data.dateCreate;
    this.position = data.position;
    this.createdByCrewAgency = data.createdByCrewAgency;
    this.view = data.view;
    this.form = new JobOfferInputFormDto(data.form);
    this.additional = data.additional ? new JobOfferItemAdditionalDto(data.additional) : null;
    this.boat = data.boat;
    this.reasonOfUnpublishing = data.reasonOfUnpublishing;
    this.covidVaccination = data.covidVaccination;
    this.couple = data.couple;
  }
}

export interface JobOfferSearchFormI {
  id: number | null;
  name: string | null;
  jobOfferKindContract: number[] | null;
  jobOfferSkill: number[] | null;
  positionGroupId: number | null;
  positionId: number | null;
  languageOneValue: number | null;
  languageOneLevel: number | null;
  languageTwoValue: number | null;
  languageTwoLevel: number | null;
  languageThreeValue: number | null;
  languageThreeLevel: number | null;
  experienceFrom: number | null;
  experienceTo: number | null;
  smokingStatusId: SmokingStatus | null;
  tattooStatusId: TattooStatus | null;
  offeredSalary: number | null;
  offeredSalaryMax: number | null;
  offeredSalaryCurrencyId: number | null;
  covidVaccination: boolean | null;
  coupleConnect: number | null;
}

export interface JobOfferQuestionDto {
  text: string;
}

export class JobOfferInputFormDto implements JobOfferSearchFormI {
  readonly id: number;
  readonly boatId: number;
  readonly joiningPlace: JobOfferPlace | null;
  readonly name: string;
  readonly jobOfferKindContract: number[];
  readonly jobOfferSkill: number[] | null;
  readonly jobOfferCruisingArea: number[] | null;
  readonly jobOfferDocument: number[];
  readonly published: PublishStatus;
  readonly positionGroupId: number;
  readonly positionId: number;
  readonly offeredSalary: number;
  readonly offeredSalaryMax: number | null;
  readonly negotiable: ConditionalStatus;
  readonly offeredSalaryCurrencyId: number;
  readonly salaryPeriod: number;
  readonly estimatedStartDate: string;
  readonly flexible: FlexibleDate;
  readonly endDate: string | null;
  readonly experienceFrom: number;
  readonly experienceTo: number;
  readonly description: string | null;
  readonly tattooStatusId: TattooStatus;
  readonly smokingStatusId: SmokingStatus;
  readonly languageOneValue: number | null;
  readonly languageOneLevel: number | null;
  readonly languageTwoValue: number | null;
  readonly languageTwoLevel: number | null;
  readonly languageThreeValue: number | null;
  readonly languageThreeLevel: number | null;
  readonly createdByCrewAgency: number | null;
  readonly useCurrentBoatPosition: boolean;
  readonly covidVaccination: boolean | null;
  readonly questions: JobOfferQuestionDto[] | null;
  readonly coupleConnect: number | null;
  readonly boatType: number[] | null;

  constructor(
    form: JobOffersFormI,
  ) {
    this.id = form.id;
    this.boatId = form.boatId;
    this.description = form.description;
    this.estimatedStartDate = form.estimatedStartDate;
    this.endDate = form.endDate;
    this.experienceFrom = form.experienceFrom;
    this.experienceTo = form.experienceTo;
    this.flexible = !!form.flexible ? FlexibleDate.Flexible : FlexibleDate.Austere;
    const {jobOfferDocument, jobOfferKindContract, jobOfferSkill, jobOfferCruisingArea} = form;
    this.jobOfferDocument = JobOfferInputFormDto.transformToIdList(jobOfferDocument);
    this.jobOfferKindContract = JobOfferInputFormDto.transformToIdList(jobOfferKindContract);
    this.languageOneValue = form.languageOneValue;
    this.languageOneLevel = form.languageOneLevel;
    this.languageTwoValue = form.languageTwoValue;
    this.languageTwoLevel = form.languageTwoLevel;
    this.languageThreeValue = form.languageThreeValue;
    this.languageThreeLevel = form.languageThreeLevel;
    this.jobOfferSkill = JobOfferInputFormDto.transformToIdList(jobOfferSkill);
    this.jobOfferCruisingArea = JobOfferInputFormDto.transformToIdList(jobOfferCruisingArea);
    this.joiningPlace = form.joiningPlace;
    this.name = form.name;
    this.offeredSalary = form.offeredSalary;
    this.offeredSalaryMax = form.offeredSalaryMax ? form.offeredSalaryMax : null;
    this.negotiable = form.negotiable;
    this.offeredSalaryCurrencyId = form.offeredSalaryCurrencyId;
    this.positionGroupId = form.positionGroupId;
    this.positionId = form.positionId;
    this.published = form.published ? PublishStatus.Published : PublishStatus.UnPublished;
    this.salaryPeriod = form.salaryPeriod;
    this.smokingStatusId = form.smokingStatusId;
    this.tattooStatusId = form.tattooStatusId;
    this.createdByCrewAgency = form.createdByCrewAgency;
    this.useCurrentBoatPosition = form.useCurrentBoatPosition;
    this.covidVaccination = form.covidVaccination;
    this.questions = form.questions;
    this.coupleConnect = form.coupleConnect;
    this.boatType = JobOfferInputFormDto.transformToIdList(form.boatType);
  }

  static transformToIdList(data: EntryDto[] | null): number[] {
    if (!data) {
      return [];
    }
    return data.map(el => el.id);
  }
}

export class JobOfferItemAdditionalDto {
  readonly applied: number;
  readonly appliedActions: number;
  readonly sent: number;
  readonly sentActions: number;

  constructor(
    additional: JobOffersListItemAdditionalI,
  ) {
    this.applied = +additional.applied;
    this.appliedActions = +additional.appliedActions;
    this.sent = +additional.sent;
    this.sentActions = +additional.sentActions;
  }
}

export class AddJobOfferPayload {
  boatId: number;
  id?: number | null;
  readonly description: string | null;
  readonly estimatedStartDate: string;
  readonly endDate: string | null;
  readonly experienceFrom: number;
  readonly experienceTo: number;
  readonly flexible: FlexibleDate | null;
  readonly jobOfferDocument: number[];
  readonly jobOfferKindContract: number[];
  readonly languageOneValue: number | null;
  readonly languageOneLevel: number | null;
  readonly languageTwoValue: number | null;
  readonly languageTwoLevel: number | null;
  readonly languageThreeValue: number | null;
  readonly languageThreeLevel: number | null;
  readonly jobOfferSkill: number[] | null;
  readonly joiningPlace: JobOfferPlace | null;
  readonly name: string;
  readonly offeredSalary: number;
  readonly offeredSalaryMax: number;
  readonly negotiable: ConditionalStatus;
  readonly offeredSalaryCurrencyId: number;
  readonly positionGroupId: number;
  readonly jobOfferCruisingArea: number[];
  readonly positionId: number;
  readonly published: PublishStatus;
  readonly salaryPeriod: number;
  readonly smokingStatusId: SmokingStatus;
  readonly tattooStatusId: SmokingStatus;
  readonly updateCrew: UpdateCrewStatus | null;
  readonly reason: UnpublishReason | null;
  readonly useCurrentBoatPosition: ConditionalStatus;
  readonly userId?: number;
  readonly covidVaccination: ConditionalStatus;
  readonly question: string[];
  readonly coupleConnect: number | null;
  readonly coupleRemove: number | null = null;
  readonly boatType: number[] | null;

  constructor(
    boatId: number,
    form: JobOffersPayloadFormI,
    updateCrew: UpdateCrewStatus | null = null,
    reason: UnpublishReason | null = null,
    userId?: number,
  ) {
    this.boatId = boatId;
    this.id = form.id ? form.id : null;
    this.description = form.description;
    this.estimatedStartDate = form.estimatedStartDate;
    this.endDate = form.endDate;
    this.experienceFrom = (form?.greeny ? 0 : form.experience?.min) || 0;
    this.experienceTo = form.experience?.max || 10;
    this.flexible = !!form.flexible ? FlexibleDate.Flexible : FlexibleDate.Austere;
    this.jobOfferDocument = form.jobOfferDocument;
    this.jobOfferKindContract = form.jobOfferKindContract;
    const {languages} = form;
    if (languages) {
      this.languageOneValue = languages.languageOneValue;
      this.languageOneLevel = languages.languageOneLevel;
      this.languageTwoValue = languages.languageTwoValue;
      this.languageTwoLevel = languages.languageTwoLevel;
      this.languageThreeValue = languages.languageThreeValue;
      this.languageThreeLevel = languages.languageThreeLevel;
    }
    this.jobOfferSkill = form.jobOfferSkill;
    this.joiningPlace = form.joiningPlace ? new JobOfferPlace(form.joiningPlace) : null;
    this.name = form.name;
    this.offeredSalary = form.offeredSalary;
    this.offeredSalaryMax = form.offeredSalaryMax;
    this.negotiable = form.negotiable ? ConditionalStatus.NO : ConditionalStatus.YES;
    this.offeredSalaryCurrencyId = form.offeredSalaryCurrencyId;
    this.positionGroupId = form.positionGroupId;
    this.jobOfferCruisingArea = form.jobOfferCruisingArea;
    this.positionId = form.positionId;
    this.published = form.published ? PublishStatus.Published : PublishStatus.UnPublished;
    this.salaryPeriod = form.salaryPeriod;
    this.smokingStatusId = form.smokingStatusId;
    this.tattooStatusId = form.tattooStatusId;
    this.updateCrew = updateCrew;
    this.reason = reason;
    this.userId = userId;
    this.useCurrentBoatPosition = booleanToConditionalStatus(form.useCurrentBoatPosition);
    this.covidVaccination = form.covidVaccination ? ConditionalStatus.YES : ConditionalStatus.NO;
    this.question = form.question?.filter(q => !!q);
    this.coupleConnect = form.coupleConnect;
    this.coupleRemove = form.coupleRemove;
    this.boatType = form.boatType;
  }
}

export class JobOfferForSearchParams {
  jobOfferId: number | null = null;
  professionalSkills: number[] | null = null;
  positionGroup: number | null = null;
  position: number | null = null;
  contractType: number[] | null = null;
  yachtExperienceMin: number | null = null;
  yachtExperienceMax: number | null = null;
  notSmocking: number | null = null;
  notVisibleTattoos: number | null = null;
  expectedSalaryMin: number | null = null;
  expectedSalaryMax: number | null = null;
  expectedSalaryCurrency: number | null = null;
  languageOneValue: number | null = null;
  languageOneLevel: number | null = null;
  languageTwoValue: number | null = null;
  languageTwoLevel: number | null = null;
  languageThreeValue: number | null = null;
  languageThreeLevel: number | null = null;
  covidVaccination: number | null = null;
  couple: ConditionalStatus | null = null;

  constructor(form: JobOfferSearchFormI, groups: RolePositionTypeDto[]) {
    const {
      id,
      positionGroupId,
      positionId,
      experienceFrom,
      experienceTo,
    } = form;
    this.jobOfferId = id;
    this.positionGroup = positionGroupId;
    const group = groups.find(gr => gr.id === positionGroupId);
    if (group) {
      this.position = group.useInFilterOpenOfferByUrl ? positionId : null;
    }
    this.yachtExperienceMin = experienceFrom;
    this.yachtExperienceMax = experienceTo;
  }
}

export interface JobOfferAnswerI {
  text: string;
  answer: ConditionalStatus;
}

export class JobOfferAnswerDto {
  readonly text: string;
  readonly answer: ConditionalStatus;

  constructor(
    data: JobOfferAnswerI,
  ) {
    this.text = data.text;
    this.answer = data.answer;
  }
}

export interface JobOfferCardI {
  readonly jobOfferId: number;
  readonly jobOfferInvitationId: number;
  readonly contacts: CrewCvContactsDto;
  readonly status: number;
  readonly createdByCrewAgency: number;
  readonly private: string;
  readonly user: JobOfferUserCardDto;
  readonly position: CrewPositionDto;
  readonly location: JobOfferLocation;
  readonly salary: ExpectedSalaryDto;
  readonly experience: string;
  readonly dateCreate: string;
  readonly dateUpdate: string;
  readonly additional: JobOfferCardAdditionalInformation;
  readonly invStatus: JobOfferInvitationStatusType;
  readonly canSendOfferAgain: boolean;
  readonly rating: number | null;
  readonly verifiedReference: boolean;
  readonly couple: CoupleDto;
  readonly coverLetter: CoverLetterDto;
  readonly answers: JobOfferAnswerI[];
  readonly matching: SearchMatchingDto;
  readonly boatTypes: SearchBoatTypes[];
}

export class JobOffersReadyListPagination {
  constructor(
    public readonly list: JobOfferCardDto[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class JobOfferCardDto {
  readonly jobOfferId: number;
  readonly jobOfferInvitationId: number;
  readonly contacts: CrewCvContactsDto;
  readonly createdByCrewAgency: number;
  readonly status: number;
  readonly private: string;
  readonly user: JobOfferUserCardDto;
  readonly position: CrewPositionDto;
  readonly location: JobOfferLocation;
  readonly salary: ExpectedSalaryDto;
  readonly experience: string;
  readonly dateCreate: string;
  readonly dateUpdate: string;
  readonly dateUpdateStatus: string;
  readonly additional: JobOfferCardAdditionalInformation;
  readonly invStatus: JobOfferInvitationStatusType;
  readonly canSendOfferAgain: boolean;
  readonly canRevise: boolean;
  readonly contractInvitation: ContractInvitationDto;
  readonly rating: number | null;
  readonly verifiedReference: boolean;
  readonly couple: CoupleDto;
  readonly coverLetter: CoverLetterDto;
  readonly answers: JobOfferAnswerDto[];
  readonly matching: SearchMatchingDto;
  readonly boatTypes: SearchBoatTypes[];

  constructor(
    data: JobOfferCardI,
  ) {
    this.jobOfferId = data.jobOfferId;
    this.jobOfferInvitationId = data.jobOfferInvitationId;
    this.contacts = data.contacts;
    this.status = data.status;
    this.createdByCrewAgency = data.createdByCrewAgency;
    this.private = data.private;
    this.matching = data.matching;
    this.user = data.user;
    this.position = data.position;
    this.location = data.location;
    this.salary = data.salary;
    this.experience = data.experience;
    this.dateCreate = data.dateCreate;
    this.dateUpdate = data.dateUpdate;
    this.additional = data.additional;
    this.invStatus = data.invStatus;
    this.canSendOfferAgain = data.canSendOfferAgain;
    this.rating = data.rating;
    this.verifiedReference = data.verifiedReference;
    this.couple = data.couple;
    this.coverLetter = data.coverLetter;
    this.answers = data.answers;
    this.boatTypes = data.boatTypes;
  }
}

export class JobOfferUserCardDto {
  constructor(
    public readonly id: number,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly age: number,
    public readonly avatar: string,
    public readonly email: string,
    public readonly lastLogin: string,
    public readonly documents: CrewSearchCvDocumentsDto,
    public readonly workPreferenceContracts: CrewSearchContractItemDto[],
  ) {
  }
}

export class JobOfferLocation {
  constructor(
    public readonly name: string,
    public readonly distance: number,
  ) {
  }
}

export class JobOfferCardAdditionalInformation {
  constructor(
    public note: string,
    public readonly video: string,
    public readonly lookingJob: CrewSearchStatus,
  ) {

  }
}

export interface AllJobOfferListPayload extends BoatPayload {
  userId: number;
}

export interface AllJobOfferListI {
  publisched: ShortJobOfferItemI[];
  unpublisched: ShortJobOfferItemI[];
}

export interface ShortJobOfferItemI {
  id: number;
  active: boolean;
  name: string;
  contractType: EntryDto[];
  offeredSalary: number;
  offeredSalaryMax: number;
  negotiable: boolean;
  offeredSalaryCurrency: string;
  salaryPeriod: SalaryInPeriod;
  position: PositionUser;
  additional: ShortJobOfferAdditionalItemI;
  invStatus: JobOfferInvitationStatusType | null;
  createdByCrewAgency: number | null;
}

export interface ShortJobOfferAdditionalItemI {
  canSendOfferAgain: boolean;
  jobOfferInvitationId: number | null;
}

export class BoatJobOfferCountDto {
  constructor(
    public readonly favorite: number,
    public readonly notRelevant: number,
    public readonly applied?: BoatAppliedJobOfferCountDto,
    public readonly sent?: BoatSentJobOfferCountDto,
    public readonly appliedActions?: BoatAppliedJobOfferActionsCountDto,
    public readonly sentActions?: BoatSentJobOfferActionsCountDto,
  ) {
  }
}

export class BoatAppliedJobOfferCountDto {
  constructor(
    public readonly all: number,
    public readonly allActive: number,
    public readonly declined: number,
    public readonly inProcess: number,
    public readonly newOffer: number,
    public readonly jobOfferHired: number,
  ) {
  }
}

export type BoatAppliedJobOfferCountType = keyof BoatAppliedJobOfferCountDto;

export class BoatSentJobOfferCountDto {
  constructor(
    public readonly all: number,
    public readonly allActive: number,
    public readonly declined: number,
    public readonly inProcess: number,
    public readonly jobOfferHired: number,
    public readonly pending: number,
  ) {
  }
}

export type BoatSendJobOfferCountType = keyof BoatSentJobOfferCountDto;

export type JobOfferCountType = BoatAppliedJobOfferCountType | BoatSendJobOfferCountType;

export type BoatSentJobOfferActionsCountDto = Partial<BoatSentJobOfferCountDto>;

export type BoatAppliedJobOfferActionsCountDto = Partial<BoatAppliedJobOfferCountDto>;

export class JobOfferCountPayload {
  constructor(
    public readonly boatId: number,
    public readonly jobOfferId?: number,
  ) {
  }
}

export class CrewJobOfferDetailsDto {
  constructor(
    public readonly jobOffer: CrewJobOfferDetailsViewDto,
    public readonly boat: BoatShortViewDto,
  ) {
  }
}

export class CrewJobOfferDetailsViewDto {
  constructor(
    public readonly id: number,
    public readonly boatId: number,
    public readonly canNotRelevant: boolean,
    public readonly name: string,
    public readonly description: string,
    public readonly endDate: string,
    public readonly estimatedStartDate: string | null,
    public readonly flexible: boolean,
    public readonly experienceFrom: number,
    public readonly experienceTo: number,
    public readonly jobOfferDocument: EntryDto[],
    public readonly jobOfferKindContract: EntryDto[],
    public readonly jobOfferLanguage: LanguageEntryDto[],
    public readonly jobOfferSkill: EntryDto[],
    public readonly jobOfferCruisingArea: EntryDto[],
    public readonly joiningPlace: JobOfferPlace,
    public readonly offeredSalary: number,
    public readonly offeredSalaryMax: number,
    public readonly negotiable: boolean,
    public readonly offeredSalaryCurrency: string,
    public readonly salaryPeriod: string,
    public readonly position: PositionUser,
    public readonly published: PublishStatus,
    public readonly smokingStatusId: string,
    public readonly tattooStatusId: string,
    public readonly createdByCrewAgency: CreatedByCrewAgencyDto,
    public readonly reasonOfUnpublishing: string,
    public readonly invStatus?: JobOfferInvitationStatusType,
    public readonly status?: JobOfferStatusType,
    public readonly canSendApplyAgain?: boolean,
    public readonly canRevise?: boolean,
    public readonly questions?: JobOfferQuestionDto[],
    public readonly matchingLabel?: MatchingResultType,
    public readonly couple?: JobOfferCoupleDto | null,
  ) {
  }

}

export class ReasoneUnpublishingDictionary {
  constructor(
    public readonly reasonOfUnpublishing: UnpublishOfferReason[],
  ) {
  }
}

export class UnpublishOfferReason implements LabeledItem {
  readonly id: UnpublishReason;
  readonly name: string;

  constructor(data: UnpublishReasonI) {
    this.id = data.id;
    this.name = data.description;
  }
}

export class UnpublishJobOfferDirectories {
  constructor(
    public readonly actions: LabeledItem[],
    public readonly reasons: LabeledItem[],
  ) {
  }
}

export interface UnpublishOfferReasonI {
  id: UnpublishReason;
  description: string;
}

export interface CreatedByCrewAgencyI {
  id: number;
  email: string;
  logo: string;
  name: string;
  phone: PhoneNumber;
  status: CompanyReadyStatus;
  type: CompanyType;
}

export class CreatedByCrewAgencyDto implements CreatedByCrewAgencyI {
  constructor(
    public readonly id: number,
    public readonly email: string,
    public readonly logo: string,
    public readonly name: string,
    public readonly phone: PhoneNumber,
    public readonly status: CompanyReadyStatus,
    public readonly type: CompanyType,
  ) {
  }
}

export class JobOfferExpectedSalaryCurrencyDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly badge: string,
    public readonly min: number,
    public readonly max: number,
  ) {
  }
}

export interface JobOfferUsefulInfo {
  readonly expectedSalary: JobOfferSalariesInfo;
}

export interface JobOfferSalariesInfo {
  readonly applied: JobOfferExpectedSalaryCurrencyDto[];
  readonly sent: JobOfferExpectedSalaryCurrencyDto[];
}

export class JobOfferFilterDirectories {
  constructor(
    public readonly positionTypes: RolePositionTypeDto[],
    public readonly cvAvailableStatuses: CheckboxGroupItem[],
    public readonly skills: CheckboxGroupItem[],
    public readonly salary: JobOfferExpectedSalaryCurrencyDto[],
    public readonly languages: LanguageDto[],
    public readonly levels: LanguageLevelDto[],
    public readonly crewSearchStatuses: CrewSearchStatusItem[],
    public readonly allPositions: RolePositionDto[],
    public readonly chipsPositionGroups: SearchChipsItemDto[],
    public readonly chipsPositions: SearchChipsItemDto[],
    public readonly chipsExperiences: SearchChipsItemDto[],
    public readonly chipsGreeny: SearchChipsItemDto,
    public readonly countries: CountryDto[],
    public readonly cvVisaGroup: CvVisaGroupDto[],
    public readonly chipsCouple: SearchChipsItemDto,
    public readonly chipsCovid: SearchChipsItemDto,
    public readonly boatTypeSections: BoatTypeSectionDto[],
    public readonly boatTypes: BoatTypeDto[],
    public readonly chipsBoatTypeSections: SearchChipsItemDto[],
  ) {
  }
}

export class JobOfferSearchFilterForm {
  constructor(
    public readonly positionGroup: number[] | null = null,
    public readonly position: number[] | null = null,
    public readonly lookingJobStatus: number | null = null,
    public readonly availability: AvailabilityFormI | null = null,
    public readonly languages: LanguageLevelFormI | null = null,
    public readonly expectedSalary: ExpectedSalaryFormI | null = null,
    public readonly professionalSkills: CheckboxGroupedValue | null = null,
    public readonly experience: RangeDto | null = null,
    public readonly greeny: boolean | null = null,
    public readonly notVisibleTattoos: boolean | null = null,
    public readonly notSmocking: boolean | null = null,
    public readonly passportCountry: number | null = null,
    public readonly visa: number[] | null = null,
    public readonly covidVaccination: boolean | null = null,
    public readonly couple: boolean | null = null,
    public readonly boatType: number[] | null = null,
    public readonly boatTypeSection: number[] | null = null,
  ) {
  }
}

export class JobOfferSearchFilterParams {
  readonly positionGroup: number[] | null = null;
  readonly position: number[] | null = null;
  readonly lookingJobStatus: number | null = null;
  readonly availabilityStatus: number[] | null = null;
  readonly availabilityDate: string | null = null;
  readonly expectedSalaryCurrency: number | null = null;
  readonly expectedSalaryMin: number | null = null;
  readonly expectedSalaryMax: number | null = null;
  readonly professionalSkills: number[] | null;
  readonly languageOneValue: number | null = null;
  readonly languageOneLevel: number | null = null;
  readonly languageTwoValue: number | null = null;
  readonly languageTwoLevel: number | null = null;
  readonly languageThreeValue: number | null = null;
  readonly languageThreeLevel: number | null = null;
  readonly yachtExperienceMin: number | null = null;
  readonly yachtExperienceMax: number | null = null;
  readonly notSmocking: ConditionalStatus | null = null;
  readonly notVisibleTattoos: ConditionalStatus | null = null;
  readonly greeny: ConditionalStatus | null = null;
  readonly covidVaccination: ConditionalStatus | null = null;
  readonly passportCountry: number | null = null;
  readonly visa: number[] | null = null;
  readonly couple: ConditionalStatus | null = null;
  readonly boatType: number[] | null = null;

  constructor(form: JobOfferSearchFilterForm) {
    const {
      positionGroup,
      position,
      professionalSkills,
      languages,
      notSmocking,
      notVisibleTattoos,
      greeny,
      expectedSalary,
      experience,
      availability,
      lookingJobStatus,
      covidVaccination,
      passportCountry,
      visa,
      couple,
      boatType,
    } = form;
    this.positionGroup = this.getArrayParam(positionGroup);
    this.position = this.getArrayParam(position);
    this.lookingJobStatus = lookingJobStatus;

    if (availability) {
      this.availabilityStatus = availability.availabilityStatus;
      const statusWithDate = this.availabilityStatus && this.availabilityStatus.some(st => st === 4);
      this.availabilityDate = statusWithDate && availability.availabilityDate || null;
    }

    if (expectedSalary) {
      const {currency, salary} = expectedSalary;
      this.expectedSalaryCurrency = currency;
      this.expectedSalaryMin = salary && salary.min;
      this.expectedSalaryMax = salary && salary.max;
    }

    this.professionalSkills = this.getArrayParam(professionalSkills && professionalSkills.subItems);
    if (languages) {
      this.languageOneValue = languages.languageOneValue;
      this.languageOneLevel = languages.languageOneLevel;
      this.languageTwoValue = languages.languageTwoValue;
      this.languageTwoLevel = languages.languageTwoLevel;
      this.languageThreeValue = languages.languageThreeValue;
      this.languageThreeLevel = languages.languageThreeLevel;
    }

    this.yachtExperienceMin = experience && experience.min;
    this.yachtExperienceMax = experience && experience.max;
    this.notSmocking = notSmocking ? booleanToConditionalStatus(notSmocking) : null;
    this.notVisibleTattoos = notVisibleTattoos ? booleanToConditionalStatus(notVisibleTattoos) : null;
    this.greeny = greeny ? booleanToConditionalStatus(greeny) : null;
    this.covidVaccination = covidVaccination ? booleanToConditionalStatus(covidVaccination) : null;
    this.passportCountry = passportCountry;
    this.visa = this.getArrayParam(visa);
    this.couple = couple ? booleanToConditionalStatus(couple) : null;
    this.boatType = this.getArrayParam(boatType);
  }

  getArrayParam(val: any[] | null): any[] | null {
    if (!val || !val.length) {
      return null;
    }
    return val;
  }
}

export class JobOfferSearchAutocompleteItemDto {
  constructor(
    public readonly type: string,
    public readonly id: number,
    public readonly name: string,
    public readonly group?: string,
  ) {
  }
}

export class JobOfferTopBannerStatisticList {
  constructor(
    public readonly amount: string,
    public readonly text: string,
  ) {
  }
}

export class JobOfferTopBannerFeaturesList {
  constructor(
    public readonly text: string,
  ) {
  }
}

export interface JobOfferCoupleDto {
  readonly id: number;
  readonly name: string;
  readonly jobOfferInvitationId?: number;
}
